import React, { useEffect, useState } from "react";
import CreateProfile from "./CreateProfile";
import Onboard from "./Onboard";
import { get, post, put } from "../../../utility/fetch";
import UserManagementTable from "../../tables/UserManagementTable";
import Pagination from "rc-pagination/lib/Pagination";
import Notify from "../../../utility/notify";

const filterOptions = [
    {
        name: "Select Filter",
        value: ""
    },
    {
        name: "Active",
        value: "Activate"
    },
    {
        name: "Inactive",
        value: "Idle"
    }
]

function UserManagement() {
    const [selectedTab, setSelectedTab] = useState("create");
    const [personalData, setPersonalData] = useState(null);
    const [userID, setUserID] = useState();
    const [contactDetails, setContactDetails] = useState(null);
    const [emergencyContact, setEmergencyContact] = useState(null);
    const [profiles, setProfiles] = useState(null);
    const [totalPaginationLength, setTotalPaginationLength] = useState(0);
    const [edit, setEdit] = useState(false);
    const [onboardId, setOnboardId] = useState(null);
    const [view, setView] = useState("table");
    const [pageNumber, setPageNumber] = useState(1);
    const [roleList, setRoleList] = useState([]);

    const [filterOn, setFilterOn] = useState("");
    const [query, setQuery] = useState("")
    const [payload, setPayload] = useState(
        {
            staffId: "",
            designation: "",
            userId: Number(sessionStorage.getItem("userId")),
            roleId: 0,
            isSuperAdmin: true
        }
    );


    const handleSearch = async (localquery) => {
        setQuery(localquery);
        if (localquery?.length > 0) {
            let res = await get(`/profile/list/${localquery}/${pageNumber}/10/search-by-name`);
            console.log(res);
            if (res?.statusCode === 404) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 500) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 409) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 401) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else {
                console.log(res, res.totalPages);
                setProfiles(res?.resultList);
                setTotalPaginationLength(res?.totalPages * 10)
            }
        }


    }

    const handleSelect = async (e) => {
        setFilterOn(e.target.value);
        let res = await get(`/user/filter-list/AccountStatus/${e.target.value}/${pageNumber}/10`)
        if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            console.log(res, res.totalPages);
            setProfiles(res?.resultList);
            setTotalPaginationLength(res?.totalPages * 10)
        }

    }


    const getFilterPages = async (current) => {
        let res = await get(`/user/filter-list/AccountStatus/${filterOn}/${current}/10`)
        if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            console.log(res, res.totalPages);
            setProfiles(res?.resultList);
            setTotalPaginationLength(res?.totalPages * 10)
        }
    }

    const [managePayload, setManagePayload] = useState({
        username: "",
        accountStatus: "",
        password: ""
    });

    const [onboardPayload, setOnboardPayload] = useState({
        roleIds: [],
        email: "",
        department: "",
        workGrade: "",
        accountStatus: "",
        clinicId: 2,
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        dateOfBirth: "",
        placeOfBirth: "",
        maritalStatus: "",
        motherMaidenName: "",
        weddingAnniversary: "",
        isSuperAdmin: false,
        stateId: 0,
        nationalityId: 19,
        religionId: 0,
        signature: "",
        bvn: "",
        nin: "",
        salaryAccountNumber: "",
        salaryDomiciledBank: "",
        resumptionDate: "",
        userId: Number(sessionStorage?.getItem("userId")),

    })

    const getProfiles = async (page = 1) => {
        const response = await get(`/profile/list/${page}/10`);
        setProfiles(response.resultList);
        setTotalPaginationLength(response.totalPages * 10)
    }


    const handlePagination = (current) => {
        if (filterOn) {
            getFilterPages(current);
            setPageNumber(current);
        }
        else if (query) {
            handleSearch(query);
            setPageNumber(current);
        }
        else{
            getProfiles(current);
            setPageNumber(current);
        }


    }

    const sendPayload = async () => {

        let res = await post("/profile", { ...onboardPayload, clinicId:Number(sessionStorage.getItem("clinicId")), isSuperAdmin: false });
        if (res.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            Notify({ title: "Success", message: "User Details Created Successfully", type: "success" });
            setOnboardId(res);
            getProfiles();
            setEdit(false);
            setOnboardPayload(
                {
                    roleIds: [],
                    email: "",
                    department: "",
                    clinicId: 2,
                    workGrade: "",
                    title: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    gender: "",
                    dateOfBirth: "",
                    placeOfBirth: "",
                    maritalStatus: "",
                    motherMaidenName: "",
                    weddingAnniversary: "",
                    stateId: 0,
                    nationalityId: 19,
                    religionId: 0,
                    signature: "",
                    bvn: "",
                    nin: "",
                    salaryAccountNumber: "",
                    salaryDomiciledBank: "",
                    resumptionDate: ""
                })
            setView("table")
        }

    }

    useEffect(() => {
        getProfiles();
    }, [])

    return (
        <div className="w-100">
            {/* <h2 className="m-t-80">Settings</h2> */}

            {/* <div className="tabs m-t-20 bold-text">
                <div
                    className={`tab-item ${selectedTab === "create" ? "active" : ""}`}
                    onClick={() => setSelectedTab("create")}
                >
                    Create Profile
                </div>

                <div
                    className={`tab-item ${selectedTab === "onboard" ? "active" : ""
                        }`}
                // onClick={() => setSelectedTab("onboard")}
                >
                    Onboarding
                </div>
            </div> */}

            {view === "form" && <div>
                {
                    selectedTab === "create" &&
                    <Onboard setRoleList={setRoleList} roleList={roleList} payload={onboardPayload} setPayload={setOnboardPayload} sendPayload={sendPayload} edit={edit} setEdit={setEdit} getProfiles={getProfiles} onboardId={onboardId} data={contactDetails} setSelectedTab={setSelectedTab} />
                }
            </div>}

            {/* <CreateProfile managePayload={managePayload} setManagePayload={setManagePayload} payload={payload} setPayload={setPayload} sendPayload={sendPayload} getProfiles={getProfiles} setOnboardId={setOnboardId} onboardId={onboardId} data={personalData} userID={userID} setUserID={setUserID} setSelectedTab={setSelectedTab} /> : */}

            {view === "table" &&
                <div className="w-100">
                    <UserManagementTable filterOptions={filterOptions} handleSearch={handleSearch} handleSelect={handleSelect} setView={setView} setEdit={setEdit} setSelectedTab={setSelectedTab} setOnboardId={setOnboardId} data={profiles} setPayload={setPayload} />
                    <div className="flex flex-h-end m-t-20">
                     {totalPaginationLength > 0 && <Pagination total={totalPaginationLength} onChange={handlePagination} />}
                    </div>
                </div>
            }
        </div>
    )
}

export default UserManagement;
