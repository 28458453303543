import React from 'react'
import SelectInput from '../../Input/SelectInput'

export default function TagInputsCheck(props) {
    return (
        <div className='m-b-10 m-r-10'>
            <div className='flex'>
                {props.label && <div className={`label-box-checkbox ${props.className}`}> {props.label} </div>}
                <div style={{ height: "40px", border: "1px solid #17621A", width: "16%" }}><input type='checkbox' checked={props?.checked} onChange={props.onChange} name={props.name}  className="w-80" /></div>
            </div>
        </div>
    )
}