import { useEffect, useState } from "react";
import TagInputs from "../../../layouts/TagInputs";
import { get, put } from "../../../../utility/fetch";
import Notify from "../../../../utility/notify";
import { IoMdRemoveCircle } from "react-icons/io";

var today = new Date()?.toISOString()?.split('T')[0];

const accountStatusOptions = [
    {
        name: "Select Status",
        value: ""
    },
    {
        name: "Activate",
        value: "Activate"
    },
    {
        name: "Idle",
        value: "Idle"
    }
]



const JobInfoForm = ({ editable, edit, roleList, setEdit, setLocalTab, getProfiles, payload, setPayload, handleChange, onboardId }) => {
    const [roles, setRoles] = useState([]);
    // const getProfile = async () => {
    //     let res = await get(`/profile/profile/${onboardId}`)
    //     console.log(res);
    //     if (res?.statusCode === 400) {
    //         Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
    //         return;
    //     }
    //     if (res?.statusCode === 409) {
    //         Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
    //         return;
    //     }
    //     else if (res.statusCode === 404) {
    //         Notify({ title: "Success", message: res?.errorData[0], type: "danger" });

    //     }
    //     else {
    //         console.log(res)
    //         setPayload({ ...payload, roleId: res?.roleId, staffId: res?.staffId, accountStatus: res?.accountStatus })
    //     }
    // }

    const removeRoleId = (id) =>{
        console.log(id)

        let newRoles = payload.roleIds;
        newRoles = newRoles.filter((role) => role.roleId!== id.roleId);
        setPayload({...payload, roleIds: newRoles })
    }

    const next = () => {
        if(!payload?.accountStatus){
            Notify({ title: "Error", message: "Please select an account status", type: "danger" });
        }
        else if (payload?.roleId === 0 || payload?.roleId === "Select Role") {
            Notify({ title: "Error", message: "Please select a role", type: "danger" });
        }
        else if(!payload?.staffId){
            Notify({ title: "Error", message: "Please enter a staff ID", type: "danger" });
        }
        else if (!payload?.dateOnboarded) {
            Notify({ title: "Error", message: "Please select date onboarded", type: "danger" });
        }
        else if (!payload?.email) {
            Notify({ title: "Error", message: "Please enter an email address", type: "danger" });
        }
        else if (!/\S+@\S+\.\S+/.test(payload.email)) {
            Notify({ title: "Error", message: "Please enter a valid email address", type: "danger" });
        }
        else if (payload?.workGrade === "") {
            Notify({ title: "Error", message: "Please enter a work grade", type: "danger" });
        }
        else if (payload?.department === "") {
            Notify({ title: "Error", message: "Please enter a department", type: "danger" });
        }
        else {
            setLocalTab("personal")
        }
    }

    const getRoles = async (role) => {
        let res = await get("/role/list/1/30")

        let updatedRoles = res?.resultList
        updatedRoles?.unshift({ value: 0, name: "Select Role" });
        setRoles(updatedRoles?.map((item) => ({ value: item?.id, name: item?.name })))
    }

    useEffect(() => {
        getRoles()
        // getProfile()
    }, []);

console.log(payload.roleIds)

    return (
        <div className="w-100">

            <TagInputs type="select" options={roles} onChange={handleChange} name="roleIds" label="Role" />
            <div className="flex flex-v-center wrap">{Array.isArray(payload?.roleIds) && payload?.roleIds?.map((item) => {return <div className="role-ind no-wrap flex space-between"><span className="m-r-10">{roles.find((roleToFind)=> roleToFind.value === item.roleId)?.name}</span><span className="pointer"><IoMdRemoveCircle onClick={()=>removeRoleId(item)} color="red" /></span></div>}) }</div>
            <TagInputs minDate={today} value={payload && payload?.dateOnboarded} onChange={handleChange} type="date" name="dateOnboarded" label="Date Onboarded" />
            <TagInputs type="select" value={payload && payload?.accountStatus} options = {accountStatusOptions} onChange={handleChange} name="accountStatus" label="Account Status" />
            <TagInputs value={payload && payload?.email} onChange={handleChange} name="email" label="Email Address" />
            <TagInputs value={payload && payload?.staffId} onChange={handleChange} name="staffId" label="Staff ID" />
            <TagInputs value={payload && payload?.department} onChange={handleChange} name="department" label="Department" />
            <TagInputs value={payload && payload?.workGrade} onChange={handleChange} name="workGrade" label="Work Grade" />
            {/* <TagInputs value={payload && payload?.designation} onChange={handleChange} name="designation" label="Designation" /> */}
            {/* <div className="flex flex-v-center">
                <input className="checksbox m-r-10" onChange={handleChange} value={payload && payload?.isSuperAdmin} type="checkbox" name="isSuperAdmin" /> <p>Assign User Super Admin Rights</p>
            </div> */}

            {
                editable &&
                <div className="flex m-t-20">
                    <button onClick={next} className="btn w-100" >Next</button>
                </div>
            }


        </div>
    )
}
export default JobInfoForm;