import React, { useEffect, useState } from "react";
import Personal from "./Patient/Personal";
import ContactDetails from "./Patient/ContactDetails";
import EmergencyContact from "./Patient/EmergencyContact";
import MedicalRecord from "./Patient/MedicalRecord";
import Immunization from "./Patient/Immunization";
import Visits from "./Patient/Visits";
import Treatments from "./Patient/Treatments";
import { allergyData } from "./mockdata/PatientData";
import Labs from "./Patient/Labs";
import Finance_HMO from "./Patient/Finance_HMO";
import { get } from "../../utility/fetch";

function PatientDetails() {
  const [selectedTab, setSelectedTab] = useState("personal");

  const [personalData, setPersonalData] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [emergencyContact, setEmergencyContact] = useState(null);
  const [medicalRecord, setMedicalRecord] = useState(null);
  const [immunization, setImmunization] = useState(null);
  const [visits, setVisits] = useState(null);
  const [treatments, setTreatments] = useState(null);
  const [labs, setLabs] = useState(null);
  const [finance_hmo, setFinance_hmo] = useState(null);
  const [allergies, setAllergies] = useState(null);


  const getPersonalData = async () => {
    let res = await get(`/patients/AllPatientById?patientId=${sessionStorage.getItem("patientId")}`)
    console.log(res);
    setPersonalData(res);
  }

  const getContactData = async () => {
    let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/contact`)
    console.log(res);
    setContactDetails(res);
  }

  const getEmergencyContactData = async () => {
    let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/emergencyContact`)
    console.log(res);
    setEmergencyContact(res);
  }

  const getMedicalRecordData = async () => {
    let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/MedicalRecord`)
    console.log(res);
    setMedicalRecord(res);
  }

  const getImmunizationRecordData = async () => {
    let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/ImmunizationRecord`)
    console.log(res);
    setImmunization(res);
  }

  const getVisitRecordData = async () => {
    let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/VisitRecord`)
    console.log(res);
    setVisits(res);
  }

  const getTreatmentRecordData = async () => {
    let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/TreatmentRecord`)
    console.log(res);
    setTreatments(res);
  }


  useEffect(() => {
    getPersonalData();
    getContactData();
    getEmergencyContactData();
    getMedicalRecordData();
    getImmunizationRecordData();
    getVisitRecordData();
    getTreatmentRecordData();
  }, [])

  const renderTabContent = (selectedTab) => {
    switch (selectedTab) {
      case "personal":
        return <Personal renderTabContent={renderTabContent} />;

      case "contactDetails":
        return <ContactDetails renderTabContent={renderTabContent} />;

      case "emergencyContact":
        return <EmergencyContact renderTabContent={renderTabContent} />;

      case "medicalRecord":
        return <MedicalRecord renderTabContent={renderTabContent} />;

      case "immunization":
        return <Immunization renderTabContent={renderTabContent} />;

      case "visits":
        return <Visits renderTabContent={renderTabContent} />;

      case "treatment":
        return <Treatments renderTabContent={renderTabContent} />;

      case "labs":
        return <Labs renderTabContent={renderTabContent} />;

      case "financeHmo":
        return <Finance_HMO />;

      default:
        return <Personal renderTabContent={renderTabContent} />;
    }
  };
  return (
    <div className="w-100">
      <div className="m-t-80">PatientDetails</div>

      <div className="tabs m-t-20 bold-text">
        <div
          className={`tab-item ${selectedTab === "personal" ? "active" : ""}`}
          onClick={() => setSelectedTab("personal")}
        >
          Personal
        </div>

        <div
          className={`tab-item ${selectedTab === "contactDetails" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("contactDetails")}
        >
          Contact Details
        </div>

        <div
          className={`tab-item ${selectedTab === "emergencyContact" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("emergencyContact")}
        >
          Emergency Contact
        </div>

        <div
          className={`tab-item ${selectedTab === "medicalRecord" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("medicalRecord")}
        >
          Medical Record
        </div>

        <div
          className={`tab-item ${selectedTab === "immunization" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("immunization")}
        >
          Immunization
        </div>
        <div
          className={`tab-item ${selectedTab === "vitals" ? "active" : ""}`}
          onClick={() => setSelectedTab("visits")}
        >
          Visits
        </div>
        <div
          className={`tab-item ${selectedTab === "treatment" ? "active" : ""}`}
          onClick={() => setSelectedTab("treatment")}
        >
          Treatment
        </div>
        <div
          className={`tab-item ${selectedTab === "medication" ? "active" : ""}`}
          onClick={() => setSelectedTab("medication")}
        >
          Medication
        </div>
        <div
          className={`tab-item ${selectedTab === "appointment" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("appointment")}
        >
          Appointment
        </div>
        <div
          className={`tab-item ${selectedTab === "labs" ? "active" : ""}`}
          onClick={() => setSelectedTab("labs")}
        >
          Labs
        </div>
        <div
          className={`tab-item ${selectedTab === "insurance" ? "active" : ""}`}
          onClick={() => setSelectedTab("financeHmo")}
        >
          Insurance
        </div>
      </div>

      <div>
        {
          selectedTab === "personal" ? <Personal data = {personalData} setSelectedTab={setSelectedTab} /> :
            selectedTab === "contactDetails" ?
              <ContactDetails data = {contactDetails} setSelectedTab={setSelectedTab} /> :
              selectedTab === "emergencyContact" ?
                <EmergencyContact data = {emergencyContact} setSelectedTab={setSelectedTab} /> :
                selectedTab === "medicalRecord" ?
                  <MedicalRecord data = {medicalRecord} setSelectedTab={setSelectedTab} /> :
                  selectedTab === "immunization" ?
                    <Immunization data = {immunization} setSelectedTab={setSelectedTab} /> :
                    selectedTab === "visits" ?
                      <Visits data = {visits} setSelectedTab={setSelectedTab} /> :
                      selectedTab === "treatment" ?
                        <Treatments data = {treatments} setSelectedTab={setSelectedTab} /> :
                        selectedTab === "labs" ?
                          <Labs data = {labs} setSelectedTab={setSelectedTab} /> :
                          selectedTab === "financeHmo" ?
                            <Finance_HMO data = {finance_hmo} /> : null

        }
      </div>
    </div>
  )
}

export default PatientDetails;
