import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PatientsTable from "../tables/PatientsTable";
import { PatientData, stats } from "./mockdata/PatientData";
import StatCard from "../UI/StatCard";
import { RiCalendar2Fill } from "react-icons/ri";
import SearchInput from "../../Input/SearchInput";
import SelectInput from "../../Input/SelectInput";
import HeaderSearch from "../../Input/HeaderSearch";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { get } from "../../utility/fetch";
import Notify from "../../utility/notify";
import Pagination from "rc-pagination/lib/Pagination";
import HeaderSelect from "../../Input/HeaderSelect";


const filterOptions = [
  {
      name: "Select Filter",
      value: ""
  },
  {
      name: "Active",
      value: "Activate"
  },
  {
      name: "Inactive",
      value: "Idle"
  }
]



function Patients() {

  useEffect(() => {
    getAllPatients(1);
  }, [])

  const [allPatients, setAllPatients] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [totalPaginationCount, setTotalPaginationCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterOn, setFilterOn] = useState("");
  const [query, setQuery] = useState("")
  const [totalPaginationLength, setTotalPaginationLength] = useState(1);




  // Function to handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  let navigate = useNavigate()
  // Function to format the date as "dd-MM-yyyy"
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  


  const handleSearch = async (localquery) => {
    setQuery(localquery);
    if (localquery?.length > 0) {
        let res = await get(`/patient/list/${localquery}/search-by-name`);
        console.log(res);
        if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            // console.log(res, res.totalPages);
            setAllPatients(res?.resultList);
            // setTotalPaginationLength(res?.totalPages * 10)
        }
    }
    else{
      let res = await get(`/patient/list`);
      console.log(res);
      if (res?.statusCode === 404) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
      }
      else if (res?.statusCode === 500) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
      }
      else if (res?.statusCode === 409) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
      }
      else if (res?.statusCode === 401) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
      }
      else {
          // console.log(res, res.totalPages);
          setAllPatients(res?.resultList);
          // setTotalPaginationLength(res?.totalPages * 10)
      }
    }


}

const handleSelect = async (e) => {
    setFilterOn(e.target.value);
    let res = await get(`/user/filter-list/AccountStatus/${e.target.value}/${pageNumber}/10`)
    if (res?.statusCode === 404) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 500) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 409) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 401) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else {
        console.log(res, res.totalPages);
        setAllPatients(res?.resultList);
        setTotalPaginationLength(res?.totalPages * 10)
    }

}


const getFilterPages = async (current) => {
    let res = await get(`/user/filter-list/AccountStatus/${filterOn}/${current}/10`)
    if (res?.statusCode === 404) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 500) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 409) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else if (res?.statusCode === 401) {
        Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
        return;
    }
    else {
        console.log(res, res.totalPages);
        setAllPatients(res?.resultList);
        setTotalPaginationLength(res?.totalPages * 10)
    }
}

  // Function to check if the selected date is today
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handlePagination = (current) => {
    getAllPatients(current);
  }

  // Custom input for the date picker
  const CustomInput = ({ value, onClick }) => (
    <button
      onClick={onClick}
      onKeyDown={(e) => e.preventDefault()} // Prevent typing in the date field
      className="custom-datepicker-input flex gap-6 flex-v-center"
    >
      {isToday(selectedDate) ? "Today" : formatDate(selectedDate)}
      <RiCalendar2Fill />
    </button>
  );

  const getAllPatients = async (pageNumber) => {

    let res = await get(`/patient/list`)
    if (res.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      setAllPatients(res.resultList);
    }

  }

  return (
    <div className="w-100 m-t-80">
      <div className="flex space-between w-100" >

        <div>
          <h3>Patients Management</h3>
        </div>
        <div className="flex m-b-10">
           <div className="m-l-10"><HeaderSearch onChange={(e)=>handleSearch(e.target.value)} /></div>
         {/* <div className="m-l-10"><HeaderSelect options={filterOptions} onChange={handleSelect} /></div> */}
          {/* <div className="m-l-10"><button onClick={()=> navigate("/patients/add")} className="btn"><div className="flex flex-v-center"><AiOutlinePlus size={25} color="white"/> <p className="m-l-10">Onboard a Patient</p></div></button></div> */}
          <div>

          </div>
        </div>
      </div>

      {/* <div className="m-t-20">
        <div className="flex">
          {stats.map((stat) => (
            <div className="m-r-20" key={stat.id}>
              <StatCard data={stat} icon={stat.icon} />
            </div>
          ))}
        </div>
      </div> */}
      <div className="flex w-100 space-between">
        {/* <div className="flex gap-7 m-t-40">
          <p>Assigned Waiting Patients</p>|
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd-MM-yyyy"
            maxDate={new Date()}
            customInput={<CustomInput />}
            icon={<RiCalendar2Fill />}
          />
        </div> */}

        <div className="flex flex-v-center  w-50  gap-10">
        </div>
      </div>

      <div className="">
        <PatientsTable filterOptions={filterOptions} handleSearch={handleSearch} handleSelect={handleSelect}  data={allPatients} />
        <Pagination className="m-t-20" total={totalPaginationCount} onChange={handlePagination} />
      </div>
    </div>
  );
}

export default Patients;
