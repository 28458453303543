import { useEffect, useState } from "react";
import FacilityCard from "../UI/FacilityCard";
import TagInputs from "../layouts/TagInputs";
import { get, post } from "../../utility/fetch";
import notification from "../../utility/notification";
import BedAssignModal from "../layouts/BedAssignModal";
import Notify from "../../utility/notify";
import EquipmentTable from "../tables/EquipmentTable";
import AmbulanceTable from "../tables/AmbulanceTable";
import FacilityTable from "../tables/FacilityTable";
import ShowEquipmentUsageTable from "../tables/ShowEquipmentUsageTable";
import AmbulanceUsageTable from "../tables/AmbulanceUsageTable";
import AmbulanceModal from "../layouts/AmbulanceModal";
import generic from "../../assets/images/genericEquip.png"
import Pagination from "rc-pagination/lib/Pagination";




const location = [
  { value: "", name: "Select location" },
  { value: "Left", name: "Left" },
  { value: "Right", name: "Right" },

]

function Facility() {
  // Sample data with a patient's name
  const [selectedTab, setSelectedTab] = useState("beds");
  const [payload, setPayload] = useState({
    name: "",
    roomId: 0,
    location: "Left",
    note: "",
    userId: Number(sessionStorage.getItem("userId"))
  })
  const [rooms, setRooms] = useState([]);
  const [beds, setBeds] = useState([]);
  const [bedCategories, setBedCategories] = useState([]);
  const [assignBeds, setAssignBeds] = useState([]);
  const [recipient, setRecipient] = useState("patient");
  const [showEquipmentUsage, setShowEquipmentUsage] = useState(false)
  const [ambulances, setAmbulances] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [equipmentUsage, setEquipmentUsage] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [assignmentData, setAssignmentData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [imageData, setImageData] = useState();
  const [showAmbulanceUsage, setShowAmbulanceUsage] = useState(false)
  const [showAmbulanceModal, setShowAmbulanceModal] = useState(false)
  const [ambulanceUsageData, setAmbulanceUsageData] = useState([])
  const [ambulanceModalData, setAmbulanceModalData] = useState(null)
  const [imageSrc, setImageSrc] = useState(generic);
  const [totalBedPaginationLength, setTotalBedPaginationLength] = useState(0);
  const [totalPaginationLength, setTotalPaginationLength] = useState(0);
  const [totalEquipmentPaginationLength, setTotalEquipmentPaginationLength] = useState(0);
  const [totalAmbulancePaginationLength, setTotalAmbulancePaginationLength] = useState(0);
  const [totalAmbulanceUsagePaginationLength, setTotalAmbulanceUsagePaginationLength] = useState(0);
  const [totalEquipmentUsagePaginationLength, setTotalEquipmentUsagePaginationLength] = useState(0);
  const [ambulanceUsagePaginationNumber, setAmbulanceUsagePaginationNumber] = useState(1);
  const [equipmentUsagePaginationNumber, setEquipmentUsagePaginationNumber] = useState(1);
  const [ambulancePaginationNumber, setAmbulancePaginationNumber] = useState(1);
  const [equipmentPaginationNumber, setEquipmentPaginationNumber] = useState(1);

  const handleError = (event) => {
    event.target.src = generic;
    // setImageSrc(generic);
  };

  // function isImageUrlByExtension(url) {
  //   console.log(url)
  //   const imageExtensions = /jpe?g|png|gif|webp/i; // Case-insensitive regex for common image extensions
  //   if (url.match(imageExtensions)){

  //     setImageSrc(url);
  //   }else{
  //     setImageSrc(generic);
  //   }
  //   return url.match(imageExtensions) ? url : generic;
  // }

  const getBeds = async (pageNumber) => {
    let res = await get(`/bed/list/${pageNumber}/10`);
    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      setBeds(res.resultList);
      setTotalBedPaginationLength(res?.totalPages * 10)
    }
  }

  const getAmbulanceUsageData = async (current = 1) => {
    let res = await get(`/assignambulance/list/${current}/10`)
    console.log(res);
    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      setAmbulanceUsageData(res?.resultList);
      setTotalAmbulanceUsagePaginationLength(res?.totalPages * 10)
      setAmbulanceUsagePaginationNumber(current);
    }
  }

  const handleBedPagination = (current) => {
    getBeds(current)
  }

  const handlePagination = (current) => {
    getAssignBeds(current)
  }

  const handleEquipmentPagination = (current) => {
    getEquipment(current)
  }

  const handleAmbulancePagination = (current) => {
    getAmbulance(current)
  }

  const handleAmbulanceUsagePagination = (current) => {
    getAmbulanceUsageData(current)
  }

  const handleEquipmentUsagePagination = (current) => {
    getEquipmentUsage(current)
  }

  const getEquipmentUsage = async (current = 1) => {
    let res = await get(`/assignequipmentpatient/list/${current}/10`)
    console.log(res);
    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      setEquipmentUsage(res?.resultList);
      setTotalEquipmentUsagePaginationLength(res?.totalPages * 10);
      setEquipmentUsagePaginationNumber(current);
    }
  }

  const getAssignBeds = async (pageNumber = 1) => {
    let res = await get(`/bed/assign-bed/list/${pageNumber}/10`);
    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      setAssignBeds(res.resultList);
      setTotalPaginationLength(res.totalPages * 10);
    }
  }

  let handleAssign = (data) => {
    setShowAssignModal(true);
    setAssignmentData(data);
  }


  const handleAmbulanceModal = () => {
    setShowAmbulanceModal(true);
  }


  const clearForm = () => {
    setPayload({
      name: "",
      roomId: "",
      location: "Left",
      note: "",
      userId: Number(sessionStorage.getItem("userId"))
    });
    getRooms();
  }

  const addBed = async () => {
    const {
      name,
      roomId,
      location,
      note,
      userId
    } = payload;

    if (name === "" || roomId === "" || location === "" || note === "" || userId === "") {
      Notify({ title: "Error", message: "Please fill in all the fields", type: "danger" });
      return;
    }

    console.log(payload)
    let res = await post("/bed/bed", payload);
    console.log(res);

    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      Notify({ title: "Success", message: "You have successfully added a Bed ", type: "success" });
      setPayload({ ...payload, name: "", note: "" });
      getBeds(1);
    }

  }


  const getRooms = async () => {
    let res = await get("/room/list/1/100");
    console.log(res);

    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      let roomList = res?.resultList;
      roomList.unshift({ name: "Select Room", id: "" })
      setRooms(res?.resultList?.map((item) => ({ value: item?.id, name: item.name })));
    }
  }


  const getBedCategories = async () => {
    let res = await get("/category/list/1/100");
    console.log(res);

    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      console.log(res)
      let roomList = res?.resultList;
      roomList.unshift({ name: "Select Category", id: "" })
      setBedCategories(res?.resultList?.map((item) => ({ value: item?.id, name: item.name })));
    }
  }

  const getPatientUsage = async () => {
    let res = await get("/room/list/${current}/100");
    console.log(res);
    let roomList = res?.resultList;

    roomList.unshift({ name: "Select Room", id: "" })
    setRooms(res?.resultList?.map((item) => ({ value: item?.id, name: item.name })));
  }


  const getAmbulance = async (current = 1) => {
    let res = await get(`/equipment/ambulance/list/${current}/10`)
    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      console.log(res);
      setAmbulances(res?.resultList);
      setTotalAmbulancePaginationLength(res?.totalPages * 10);
      setAmbulancePaginationNumber(current);
    }
  }

  const getEquipment = async (current = 1) => {
    let res = await get(`/equipment/list/${current}/10`)
    if (res?.statusCode === 400) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 409) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 401) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 404) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else if (res?.statusCode === 500) {
      Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
      return;
    }
    else {
      console.log(res);
      setEquipment(res?.resultList);
      setTotalEquipmentPaginationLength(res?.totalPages * 10);
      setEquipmentPaginationNumber(current);

    }
  }


  useEffect(() => {
    getRooms();
    getBedCategories();
    getBeds(1);
    getAmbulance();
    getEquipment();
    getAssignBeds();
    getEquipmentUsage();
    getAmbulanceUsageData();
  }, [])


  const handleChange = (event) => {
    if (event.target.name === "roomId" || event.target.name === "categoryId") {
      setPayload({ ...payload, [event.target.name]: Number(event.target.value) })
    }
    else {
      setPayload({ ...payload, [event.target.name]: event.target.value })
    }
  }

  const renderTabContent = () => {
    switch (selectedTab) {
      case "beds":
        return (
          <div className=" m-t-20">
            <div className="flex">
              <div className="flex-30 m-r-20 ">
                <h3 className="m-b-10">Total Available Beds: {beds?.length}</h3>
                <TagInputs type="select" options={rooms} onChange={handleChange} name="roomId" label="Select Room" />

                <h3 className="m-t-40 m-b-10">Add Beds</h3>
                <TagInputs onChange={handleChange} value={payload?.name} name="name" label="Bed Name" />
                <TagInputs type="select" options={location} onChange={handleChange} name="location" label="Select Location" />
                <TagInputs type="select" options={bedCategories} onChange={handleChange} name="categoryId" label="Select Category" />
                <TagInputs type="textArea" onChange={handleChange} name="note" label="Add Note" value={payload?.note} />

                <div className="flex">
                  <button onClick={clearForm} className="btn-gray w-100 m-t-10 m-r-10">Clear</button>
                  <button onClick={addBed} className="btn w-100 m-t-10">Submit</button>
                </div>
                
              </div>
              <div className="flex-60 grid gap-16">
                {Array.isArray(beds) && beds?.map((bed, index) => (
                  <FacilityCard handleAssign={handleAssign} key={index} data={bed} />
                ))}
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-h-end flex-90">
                <Pagination total={totalBedPaginationLength} onChange={handleBedPagination} />
              </div>
            </div>

            <div className="w-100">
              <FacilityTable data={assignBeds}/>
              <div className="flex flex-h-end m-t-20">
                <Pagination total={totalPaginationLength} onChange={handlePagination} />
              </div>
            </div>

          </div>
        );

      case "equipments":
        // Render equipment content here
        return (
          <div className=" m-t-20">
            {!showEquipmentUsage && <div className="flex">
              <div className="flex-40 m-r-20 ">
                <h3 className="m-b-10">Total Equipment: {equipment?.length}</h3>
                <div className="flex space-between m-b-10"><h2>Manage Equipment</h2><p onClick={() => setShowEquipmentUsage(true)} className="rounded-butt pointer">View Patient Usage</p></div>
                <TagInputs type="select" options={rooms} onChange={handleChange} name="roomId" label="Select Room" />
                <div
                  className="flex space-between m-t-20"
                >
                  <p
                    onClick={() => setRecipient("patient")}
                    className={`${recipient === "patient" ? "rounded-toggle1" : "rounded-toggle2"} pointer`}>
                    Assign Equipment to Patient
                  </p>
                  <p
                    onClick={() => setRecipient("room")}
                    className={`${recipient === "room" ? "rounded-toggle1" : "rounded-toggle2 "} pointer`}>
                    Assign Equipment to Ward
                  </p>
                </div>
              </div>
              <div className="flex-50 m-r-20">
                <EquipmentTable
                  data={equipment}
                  handleEquipmentPagination={handleEquipmentPagination}
                  totalEquipmentPaginationLength={totalEquipmentPaginationLength}
                  setHoveredRow={setHoveredRow}
                  setImageData={setImageData}
                  recipient={recipient}
                  roomId={payload?.roomId}
                  handleAssign={handleAssign}
                  equipmentPageNumber={equipmentPaginationNumber}
                />
              </div>
              <div className="flex-25">
                {imageData &&
                  <div >
                    <img onError={handleError} className="equip-image" src={imageData?.image || generic} alt="" srcset="" />
                  </div>
                }
              </div>
            </div>}
            {
              showEquipmentUsage && <div className="flex w-100">
                <div className="w-100 m-r-20 ">
                  <div className="flex space-between">
                    <h3 className="m-b-10">Total Equipment: {equipment?.length}</h3>
                    <p onClick={() => setShowEquipmentUsage(false)} className="underline pointer">Manage Equipment</p>
                  </div>

                  <ShowEquipmentUsageTable
                    handleEquipmentUsagePagination={handleEquipmentUsagePagination}
                    totalEquipmentUsagePaginationLength={totalEquipmentUsagePaginationLength}
                    data={equipmentUsage} recipient={recipient}
                    roomId={payload?.roomId}
                    handleAssign={handleAssign}
                    equipmentUsagePageNumber = {equipmentUsagePaginationNumber}
                  />
                </div>
              </div>
            }
          </div>
        );

      case "ambulance":
        // Render ambulance content here
        return (
          <div className=" m-t-20">
            {!showAmbulanceUsage && <div className="flex">
              <div className="m-r-20 w-80">
                <div className="flex space-between">
                  <h3>Ambulance List</h3>
                  <div className="flex">
                    <p className="m-r-10">Total Ambulances: <b>{ambulances?.length}</b></p>
                    <p>Ambulances Available: <b>{ambulances?.length}</b></p>
                  </div>
                  <div>
                    <span onClick={() => setShowAmbulanceUsage(true)} className="underline pointer">View Ambulance Usage</span> &nbsp;  | &nbsp;
                    <span onClick={() => handleAmbulanceModal()} className="underline pointer">Assign Ambulance</span>
                  </div>

                </div>

                <AmbulanceTable
                  totalAmbulancePaginationLength={totalAmbulancePaginationLength}
                  handleAmbulancePagination={handleAmbulancePagination}
                  hoveredRow={hoveredRow}
                  setImageData={setImageData}
                  setHoveredRow={setHoveredRow}
                  data={ambulances}
                  handleAssign={handleAssign}
                  ambulancePageNumber={ambulancePaginationNumber}

                />
              </div>
              <div className="w-50">
                {imageData &&
                  <div >
                    <img onError={handleError} className="equip-image" src={imageData?.image} alt="" srcset="" />
                  </div>
                }
              </div>

            </div>}
            {
              showAmbulanceUsage &&
              <div className="flex w-100">
                <div className="w-100 m-r-20 ">
                  <div className="flex space-between">
                    <h3 className="m-b-10">Total Ambulances: {ambulances?.length}</h3>
                    <div>
                      <span onClick={() => setShowAmbulanceUsage(false)} className="underline pointer">View Ambulance List</span> &nbsp;|&nbsp;
                      <span onClick={() => handleAmbulanceModal()} className="underline pointer">Assign Ambulance</span>
                    </div>

                  </div>
                  <AmbulanceUsageTable
                    hoveredRow={hoveredRow}
                    setImageData={setImageData}
                    totalPaginationCount={totalAmbulanceUsagePaginationLength}
                    handleAmbulanceUsagePagination={handleAmbulanceUsagePagination}
                    setHoveredRow={setHoveredRow}
                    getAmbulanceUsageData={getAmbulanceUsageData}
                    data={ambulanceUsageData}
                    handleAssign={handleAssign}
                    ambulanceUsagePageNumber={ambulanceUsagePaginationNumber}
                  />

                </div>

              </div>
            }
          </div>
        );

      default:
        return null;
    };
  }

  return (
    <div className="w-100">
      <div className="m-t-20">...</div>
      <div className="m-t-20 bold-text">Facility | Bed Management</div>

      <div className="tabs m-t-20 bold-text">
        <div
          className={`tab-item ${selectedTab === "beds" ? "active" : ""}`}
          onClick={() => setSelectedTab("beds")}
        >
          Beds
        </div>

        <div
          className={`tab-item ${selectedTab === "equipments" ? "active" : ""}`}
          onClick={() => setSelectedTab("equipments")}
        >
          Equipments
        </div>

        <div
          className={`tab-item ${selectedTab === "ambulance" ? "active" : ""}`}
          onClick={() => setSelectedTab("ambulance")}
        >
          Ambulance
        </div>
      </div>


      <div className="m-t-20 bold-text">
        Assigned Patients location | Ward A
      </div>

      {renderTabContent()}

      {showAssignModal && <BedAssignModal getAssignBeds={getAssignBeds} data={assignmentData} closeModal={() => setShowAssignModal(false)} getBeds={getBeds} />}
      {showAmbulanceModal && <AmbulanceModal getAmbulanceUsageData={getAmbulanceUsageData} closeModal={() => setShowAmbulanceModal(false)} data={ambulanceModalData} />}
    </div>
  );
}

export default Facility;
