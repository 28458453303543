import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";
import TagInputs from "../../layouts/TagInputs";
import { get, post } from "../../../utility/fetch";
import notification from "../../../utility/notification";
import PriviledgeTable from "../../tables/PriviledgeTable";
let gender = [
  { value: "choose", name: "Choose Gender" },
  { value: "Male", name: "Male" },
  { value: "Female", name: "Female" },

]
let maritalStatus = [
  { value: "choose", name: "Choose Marital Status" },
  { value: "Single", name: "Single" },
  { value: "Married", name: "Married" },
]
function AssignPriviledgeForm({ setSelectedTab, data, getRoles, total }) {
  const [payload, setPayload] = useState({})
  const [priviledgeData, setPriviledgeData] = useState([])

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  
  }

  useEffect(() => {
    data && setPayload(data);
  }, [data])


  return (
    <div className="w-100">
      {" "}
      <div className="m-t-40"></div>
        <PriviledgeTable total={total} getRoles = {getRoles} data={data}/>
      {/* <button onClick={submitPayload} className="btn  m-t-20 w-100" >Continue</button> */}
    </div>
  );
}

export default AssignPriviledgeForm;
