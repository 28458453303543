import { useEffect, useState } from "react";
import HeaderSearch from "../../../Input/HeaderSearch";
import { get } from "../../../utility/fetch";
import ManageRoomTable from "../../tables/ManageRoomTable";
import Notify from "../../../utility/notify";

const ManageRooms = () => {
    const [roomData, setRoomData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [totalPaginationCount, setTotalPaginationCount] = useState(1);
    const [query, setQuery] = useState(null);
    const [filterOn, setFilterOn] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    

    const getRooms = async (pageNumber = 1) => {
        let res = await get(`/room/list/${pageNumber}/10`);
        if (res) {
            console.log(res);
            setRoomData(res?.resultList);
            setTotalPaginationCount(res?.totalPages * 10)
        }
    }

    const handleSearch = async (e) => {
        let query = e.target.value;
        setQuery(e.target.value);
       
            let res = await get(`/room/filter-list/${"RoomName"}/${query}/${pageNumber}/10`);
            console.log(res);
            if (res?.statusCode === 404) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 500) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 409) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 401) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else {
                console.log(res, res.totalPages);
                setRoomData(res?.resultList);
                setTotalPaginationCount(res?.totalPages * 10)
            }
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getRooms();
    }, [])

    return (
        <div>
            <div className="flex space-between">
                <div>
                    <h3>Available Rooms in Hospital </h3>
                </div>

                <div className="flex">
                    <div className="m-r-10"><HeaderSearch onChange={handleSearch}/></div>
                    <div><button onClick={handleShowModal} className="btn w-100 round-5">+ Add New Room</button></div>
                    <div></div>
                </div>

            </div>
            <ManageRoomTable totalPaginationCount={totalPaginationCount} getRooms={getRooms} data={roomData} closeModal={closeModal} showModal={showModal} setShowModal={setShowModal} />
        </div>
    )
}

export default ManageRooms;