import { dateHelp } from "../../utility/general";
import SearchInput from '../../Input/SearchInput'
import HeaderSearch from '../../Input/HeaderSearch'
import SelectInput from "../../Input/SelectInput";
import { useState } from "react";
import HeaderSelect from "../../Input/HeaderSelect";

const UserManagementTable = ({ data, setPayload, filterOptions, handleSearch, handleSelect, setView, setEdit, setOnboardId, setSelectedTab }) => {


    const handleRow = (row) => {

        setEdit(true);
        setPayload(
            {
                roleId: row?.roleId,
                email: row?.email,
                department: row?.department,
                workGrade: row?.workGrade,
                title: row?.title,
                firstName: row?.firstName,
                middleName: row?.middleName,
                lastName: row?.lastName,
                gender: row?.gender,
                dateOfBirth: dateHelp(row?.dateOfBirth),
                placeOfBirth: row?.placeOfBirth,
                maritalStatus: row?.maritalStatus,
                motherMaidenName: row?.motherMaidenName,
                profilePicture: row?.profilePicture,
                weddingAnniversary: dateHelp(row?.weddingAnniversary),
                stateId: row?.stateId,
                nationalityId: 19,
                religionId: row?.religionId,
                signature: row?.signature,
                bvn: row?.bvn,
                nin: row?.nin,
                salaryAccountNumber: row?.salaryAccountNumber,
                salaryDomiciledBank: row?.salaryDomiciledBank,
                resumptionDate: dateHelp(row?.resumptionDate),
                username: row?.username
            }
        );
        setOnboardId(row?.id)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        setSelectedTab("onboard")
    }


    const changeView = (view) => {
        setView(view);
    }

    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item m-t-40">
                <div className="flex space-between">
                    <h3 className="m-b-10">User/Employee Records</h3>
                    <div className="flex space-between m-b-10">
                        <HeaderSearch onChange={(e)=>handleSearch(e.target.value)} className="m-r-10" />
                        <HeaderSelect options={filterOptions} onChange={handleSelect} className="m-r-10" />
                        <button onClick={() => changeView("form")} className="btn round-5">
                            <div className="flex flex-v-center">
                                <span className="font-lg m-r-5">+</span> <span>Onboard a User</span>
                            </div>
                        </button>
                    </div>
                </div>

                <table className="bordered-table">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Gender</th>
                            <th>Status</th>
                            <th align="left">Date Created</th>
                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane ">
                        {data?.map((row, idx) => (
                            <tr onClick={() => { handleRow(row) }} className="hovers pointer" key={row.id}>
                                <td>{row?.firstName}</td>
                                <td>{row.lastName}</td>
                                <td>{row.email}</td>
                                <td>{row.gender}</td>
                                <td>{row.accountStatus}</td>
                                <td align="left">{new Date(row.createdAt).toDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <RoleAssignmentModal /> */}
        </div>
    )
}

export default UserManagementTable;