import React, { useEffect, useState } from "react";
import { months } from "../../utility/general";
import Select from "react-select";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";
import { get } from "../../utility/fetch";




const COLORS = ["#109615", "#FFC700", "#FF0000"];

function CustomerEngagement() {

  const [selectedTab, setSelectedTab] = useState(1);
  const [month, setMonth] = useState("")
  const [data, setData] = useState([
    { name: "Excellent", value: 0 },
    { name: "Just OK", value: 0 },
    { name: "Poor", value: 0 },
  ])
  const [reviewData, setReviewData] = useState([])
  const handleMonths = (month) => {
    console.log(month)
    setMonth(month?.label?.toLowerCase())
  }



  const getReviewData = async () => {
    let res = await get(`/customerengagements/customerengagements/${selectedTab}/${month || "january"}?pageIndex=1&pageSize=100`)
    console.log(res)
    setReviewData(res?.data);
  }


  const getAverage = async () => {
    let res = await get(`/customerengagements/customerengagements/${selectedTab}/${month || "january"}/average`)
    console.log(res)
    setData([
      { name: "Excellent", value: res?.excellentPercentage },
      { name: "Just OK", value: res?.justOkPercentage },
      { name: "Poor", value: res?.poorPercentage },
    ])
  }



  useEffect(() => {
    getReviewData();
    getAverage();
  }, [month, selectedTab])



  return (
    <div className="w-100">
      <div className="m-t-20">...</div>
      <h2 className="m-t-20"> Customer Management</h2>

      <div className="tabs m-t-40 w-100 bold-text">
        <div
          className={`tab-item ${selectedTab === "personal" ? "active" : ""}`}
          onClick={() => setSelectedTab(1)}
        >
          Patients
        </div>

        <div
          className={`tab-item ${selectedTab === "contactDetails" ? "active" : ""
            }`}
          onClick={() => setSelectedTab(2)}
        >
          Colleagues
        </div>
      </div>

      <div className="flex w-100 ">
        <div className="flex-basis-40  ">

          <div className="container w-100 ">
            <div className="w-100 flex flex-v-center space-between border-bottom p-b-20">
              <div className="bold-text">Patients Evaluation</div>
              <div className="w-60" >
                <Select options={months} onChange={handleMonths} />
              </div>
            </div>
            <PieChart width={300} height={300}>
              <Legend
                iconType="circle"
                layout="horizontal"
                verticalAlign="bottom"
              />
              <Pie
                data={data}
                cx={120}
                cy={100}
                innerRadius={60}
                outerRadius={75}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <Label
                  className="bold-text"
                  fontSize={24}
                  value={data.reduce((acc, entry) => acc + entry.value, 0)}
                  position="center"
                />
              </Pie>
            </PieChart>
          </div>

        </div>
        <div className="flex-50 m-l-40 ">
          {Array?.isArray(reviewData) && reviewData?.map((item) => (
            <div key={item?.id} className="flex flex-col m-b-20 m-t-5">
              <p className="m-b-10" >{`${months[new Date(item?.createdAt).getMonth()]?.label}, ${new Date(item?.createdAt).getFullYear()}`}</p>
              <div className="w-100 comment-box">
                {item?.comments}
              </div>
            </div>
          ))}

        </div>
      </div>



    </div>
  );
}

export default CustomerEngagement;
