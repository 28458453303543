import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import RoomsModal from "../layouts/RoomsModal";
import { del } from "../../utility/fetch";
import notification from "../../utility/notification";
import { deleteAlert, onYesNoAlert } from "../../utility/alert";
import Pagination from "rc-pagination";
import Notify from "../../utility/notify";

function ManageRoomTable({ data, getRooms, showModal, closeModal, setShowModal, totalPaginationCount }) {

    const [roomData, setRoomData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
  

    let navigate = useNavigate()

    const handleEdit = (row) => {
        console.log("edit", row);
        setRoomData(row);
        setShowModal(true);
    }

    const handleDelete = async (row) => {
        console.log("delete", row);

        const res = await del(`/room/${row?.id}`)
            if (res) {
                Notify({title:"Success", message:"Room deleted successfully", type:"danger"})
                closeModal()
                getRooms()
            }
    }


    const handleChangePagination = (page) => {
        setPageNumber(page);
        getRooms(page);
    }



    return (
        <div className="w-100 ">
            <div className="w-100 none-flex-item m-t-40">
                <table className="bordered-table m-b-20">
                    <thead className="border-top-none">
                        <tr className="border-top-none">
                            <th>Room Name</th>
                            <th>Room Type</th>
                            <th>Specifications</th>
                            <th>Created By</th>
                            <th>Date Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody className="white-bg view-det-pane ">
                        {data?.map((row, idx) => (
                            <tr className="hovers" key={row.id}>
                                <td>{row.name}</td>
                                <td>{row.roomType}</td>
                                <td>{row.description}</td>
                                <td>{row.userName}</td>
                                <td>{new Date(row.createdAt).toLocaleDateString()}</td>
                                <td>
                                    <div className="flex">
                                        <MdEdit onClick={() => handleEdit(row)} size={30} color="white" className="m-r-10 pointer editButton" />
                                        <MdDelete onClick={() => deleteAlert(handleDelete, row, "Are You Sure You Want To Delete Room?") } size={30} color="white" className="deleteButton pointer" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex flex-h-end">
                     <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount}/>
                </div>
               
            </div>
            {showModal && <RoomsModal data={roomData} getRooms={getRooms} setRoomData={setRoomData} setShowModal={setShowModal} closeModal={closeModal}/> }
        </div>
    );
}

export default ManageRoomTable;
