import React, { useEffect, useState } from "react";
import Personal from "../Patient/Personal";
import ContactDetails from "../Patient/ContactDetails";
import EmergencyContact from "../Patient/EmergencyContact";
import MedicalRecord from "../Patient/MedicalRecord";
import Immunization from "../Patient/Immunization";
import Visits from "../Patient/Visits";
import Treatments from "../Patient/Treatments";
import { allergyData } from "../mockdata/PatientData";
import Labs from "../Patient/Labs";
import Finance_HMO from "../Patient/Finance_HMO";
import { get } from "../../../utility/fetch";
import UserRecords from "./UserRecords";
import RolesAndPrivileges from "./RolesAndPrivileges";
import ManageRooms from "./ManageRooms";
import Vendor from "./Vendor";
import UserManagement from '../UserManagement/UserManagement'
import ManageEquipment from "./ManageEquipment";
import EquipmentName from "./EquipmentName";

function PatientDetails() {
  const [selectedTab, setSelectedTab] = useState("userRecords");
  const [personalData, setPersonalData] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [emergencyContact, setEmergencyContact] = useState(null);
  const [medicalRecord, setMedicalRecord] = useState(null);
  const [immunization, setImmunization] = useState(null);
  const [visits, setVisits] = useState(null);
  const [treatments, setTreatments] = useState(null);
  const [labs, setLabs] = useState(null);
  const [finance_hmo, setFinance_hmo] = useState(null);
  const [allergies, setAllergies] = useState(null);
 


  // const getPersonalData = async () => {
  //   let res = await get(`/patients/AllPatientById?patientId=${sessionStorage.getItem("patientId")}`)
  //   console.log(res);
  //   setPersonalData(res);
  // }

  // const getContactData = async () => {
  //   let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/contact`)
  //   console.log(res);
  //   setContactDetails(res);
  // }

  // const getEmergencyContactData = async () => {
  //   let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/emergencyContact`)
  //   console.log(res);
  //   setEmergencyContact(res);
  // }

  // const getMedicalRecordData = async () => {
  //   let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/MedicalRecord`)
  //   console.log(res);
  //   setMedicalRecord(res);
  // }

  // const getImmunizationRecordData = async () => {
  //   let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/ImmunizationRecord`)
  //   console.log(res);
  //   setImmunization(res);
  // }

  // const getVisitRecordData = async () => {
  //   let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/VisitRecord`)
  //   console.log(res);
  //   setVisits(res);
  // }

  // const getTreatmentRecordData = async () => {
  //   let res = await get(`/patients/${sessionStorage?.getItem("patientId")}/TreatmentRecord`)
  //   console.log(res);
  //   setTreatments(res);
  // }


  // useEffect(() => {
  //   getPersonalData();
  //   getContactData();
  //   getEmergencyContactData();
  //   getMedicalRecordData();
  //   getImmunizationRecordData();
  //   getVisitRecordData();
  //   getTreatmentRecordData();
  // }, [])

  return (
    <div className="w-100">
      <h2 className="m-t-80">Settings</h2>

      <div className="tabs m-t-20 bold-text">
        <div
          className={`tab-item ${selectedTab === "userRecords" ? "active" : ""}`}
          onClick={() => setSelectedTab("userRecords")}
        >
          User Records
        </div>

        <div
          className={`tab-item ${selectedTab === "roles" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("roles")}
        >
          Roles And Privileges
        </div>

        <div
          className={`tab-item ${selectedTab === "rooms" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("rooms")}
        >
          Manage Rooms
        </div>

        <div
          className={`tab-item ${selectedTab === "vendor" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("vendor")}
        >
          Vendor List
        </div>

        <div
          className={`tab-item ${selectedTab === "manageEquipment" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("manageEquipment")}
        >
          Manage Equipment
        </div>

        <div
          className={`tab-item ${selectedTab === "equipmentName" ? "active" : ""
            }`}
          onClick={() => setSelectedTab("equipmentName")}
        >
          Equipment Name
        </div>

      </div>

      <div>
        {
          selectedTab === "userRecords" ?
            <UserManagement data={personalData} setSelectedTab={setSelectedTab} /> :
            selectedTab === "roles" ?
              <RolesAndPrivileges data={contactDetails} setSelectedTab={setSelectedTab} /> :
              selectedTab === "rooms" ?
              <ManageRooms data={emergencyContact} setSelectedTab={setSelectedTab} /> :
              selectedTab === "vendor" ?
              <Vendor data={emergencyContact} setSelectedTab={setSelectedTab}/> :
              selectedTab === "manageEquipment" ?
              <ManageEquipment data={emergencyContact} setSelectedTab={setSelectedTab}/> :

              <EquipmentName data={emergencyContact} setSelectedTab={setSelectedTab}/> 
        }
      </div>
    </div>
  )
}

export default PatientDetails;
