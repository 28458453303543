import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";
import TagInputs from "../../layouts/TagInputs";
import { get, post } from "../../../utility/fetch";
import notification from "../../../utility/notification";
import AssignPrivileges from "./forms/AssignPrivileges";
import OtherInfoForm from "./forms/OtherInfoForm";
import PersonalInfoForm from "./forms/PersonalInfoForm";
import JobInfoForm from "./forms/JobInfoForm";
import CreateRole from "./CreateRole";
import AssignPriviledgeForm from "./AssignPriviledgeForm";
let gender = [
  { value: "choose", name: "Choose Gender" },
  { value: "Male", name: "Male" },
  { value: "Female", name: "Female" },

]
let maritalStatus = [
  { value: "choose", name: "Choose Marital Status" },
  { value: "Single", name: "Single" },
  { value: "Married", name: "Married" },
]
function RolesAndPrivileges({ setSelectedTab, data }) {
  const [payload, setPayload] = useState({});
  const [localTab, setLocalTab] = useState("job");
  const [rolesData, setRolesData] = useState([]);
  const [totalPaginationCount, setTotalPaginationCount] = useState(1);

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    // data && setPayload(data);
    getRoles()
  }, [data])


  const getRoles = async (page = 1) => {
    let roles = await get(`/role/list/${page}/10`);
    console.log(roles);
    if (roles) {
      setRolesData(roles?.resultList);
      setTotalPaginationCount(roles?.totalPages);
    }

  }

  // const getUsers = async () => {
  //   let users = await get("/user/list/1/10")
  //   console.log(users)
  //   setTableData(users?.resultList);
  // }

  return (
    <div className="w-100 flex">
      <div className="flex-15 m-r-20">
        <div onClick={() => setLocalTab("job")} className={`rounded-button ${localTab === "job" && "bg-green"}`}>Create Role</div>
        <div onClick={() => setLocalTab("personal")} className={`rounded-button ${localTab === "personal" && "bg-green"}`}>Assign Privileges</div>
      </div>
      <div className="flex-40 m-r-20">
        <div className="">
          {
            localTab === "job" ?
              <CreateRole payload={payload} handleChange={handleChange} getRoles={getRoles} total={totalPaginationCount} data={rolesData} /> :
              <AssignPriviledgeForm payload={payload} handleChange={handleChange} total={totalPaginationCount} getRoles={getRoles} data={rolesData} />
          }
        </div>
      </div>

    </div>
  );
}

export default RolesAndPrivileges;
