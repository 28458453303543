import React, { useState } from 'react'
import SelectInput from '../../Input/SelectInput'
import { BsSearch } from 'react-icons/bs'
import { HiOutlineEye } from "react-icons/hi2";
import { HiOutlineEyeOff } from "react-icons/hi";


export default function TagInputs(props) {
    const [show, setShow] = useState(false)
    return (
        <div className='m-b-10'>
            <div className='flex'>
                {props.label && props?.type !== "textArea" && <div className={`label-box ellipses-over ${props.className}`}> {props.label} </div>}
                {props.label && props?.type === "textArea" && <div className={`label-box-area ellipses-over ${props.className}`}> {props.label} </div>}
                {
                    props.type === 'select' ?
                        <div style={{ height: "40px", border: "1px solid #17621A", width: "100%" }}><SelectInput disabled={props?.disabled} value={props?.value} onChange={props.onChange} name={props.name} options={props?.options} className="w-100" /></div>
                        :
                        <div className="rel" style={{ height: "40px", border: "1px solid #17621A", width: "100%" }}>
                            {
                                props.type === "date" ?
                                    <input max={props?.minDate && props?.minDate} onChange={props.onChange} name={props.name} value={props.value} type={props.type} className="w-100" />
                                    :
                                    props.type === "time" ?
                                        <input max={props?.minDate && props?.minDate} onChange={props.onChange} name={props.name} value={props.value} type={props.type} className="w-100" />
                                        :
                                        props.type === "textArea" ? <textarea name={props?.name} value={props?.value} onChange={props?.onChange} rows={2} />
                                            :
                                            props.type === "selectSearch" ? <div style={{ height: "100%" }} className='w-100 white-bg'>
                                                <div style={{ height: "100%", paddingRight: "8px" }} className='flex flex-v-center w-100 white-bg'>
                                                    <input className='w-100 ' name={props?.name} value={props?.value} onChange={props?.onChange} rows={2} />
                                                    <BsSearch style={{ height: "100%" }} />
                                                </div>
                                                {Array.isArray(props?.selectSearchOptions) && props?.selectSearchOptions.length > 0 && <div className='white-bg rel options-tab m-t-5 z-10 '>
                                                    {
                                                        props?.selectSearchOptions?.length > 0 && props?.selectSearchOptions
                                                            .slice(0, 10).map((option, idx) => {
                                                                return <div key={option.id} onClick={() => props.chosenItem(option)} className='options-option'>
                                                                    <p className='p-5'>{option?.username} - {option?.email}</p>
                                                                    <hr />
                                                                </div>
                                                            }

                                                            )
                                                    }
                                                </div>}
                                            </div>
                                                : <div style={{ height: "100%" }} className='flex w-100 white-bg flex-v-center p-10 '>
                                                    <input maxLength={props?.max && props?.max} autoComplete="off" onChange={props.onChange} name={props.name} value={props.value} type={props.type === "password" ? (show ? "text" : "password") : (props?.variation) ? "number" : "text"} className="w-100" />
                                                    {props?.type === "password" && ((show) ? <HiOutlineEye onClick={() => setShow(!show)} className='pointer' size={20} /> : <HiOutlineEyeOff onClick={() => setShow(!show)} className='pointer' size={20} />)}
                                                </div>
                            }
                        </div>
                }
            </div>
        </div>
    )
}