import { post, put } from "../../../../utility/fetch";
import { reverseDateHelp } from "../../../../utility/general";
import Notify from "../../../../utility/notify";
import TagInputs from "../../../layouts/TagInputs";


const OtherInfoForm = ({ editable, setEdit, getProfiles, setSelectedTab, sendPayload, setLocalTab, payload, onboardId, handleChange }) => {

    const next = async () => {
        console.log({ ...payload, userId: Number(sessionStorage.getItem('userId')) });

        if(!payload.bvn || payload.bvn?.length !== 11 ){
            Notify({ title: "Error", message: "BVN is required and must be 11 digits", type: "danger" });
            return;
        }
        else if(!payload.nin || payload.nin?.length !== 11){
            Notify({ title: "Error", message: "NIN is required and must be 11 digits", type: "danger" });
            return;
        }
        else if(!payload?.salaryAccountNumber || payload.salaryAccountNumber?.length !== 10){
            Notify({ title: "Error", message: "Salary account number is required and must be 10 digits", type: "danger" });
            return;
        }
        else if(!payload?.salaryDomiciledBank){
            Notify({ title: "Error", message: "Salary domiciled bank is required", type: "danger" });
            return;
        }
       
        else if(!payload.resumptionDate){
            Notify({ title: "Error", message: "Resumption date is required", type: "danger" });
            return;
        }

       sendPayload()
    }

    const previous = () => {
        setLocalTab("personal")
    }

    return (
        <div className="w-100">
            {/* <TagInputs value={payload && payload?.staffId} onChange={handleChange} name="staffid" label="Staff ID" /> */}
            <TagInputs variation="number" max={11} value={payload && payload?.bvn} onChange={handleChange} name="bvn" label="BVN" />
            <TagInputs variation="number" max="11"  value={payload && payload?.nin} onChange={handleChange} name="nin" label="NIN" />
            <TagInputs variation="number" max="11" value={payload && payload?.salaryAccountNumber} onChange={handleChange} name="salaryAccountNumber" label="Salary Account Number" />
            <TagInputs value={payload && payload?.salaryDomiciledBank} onChange={handleChange} name="salaryDomiciledBank" label="Salary Domiciled Bank" />
            <TagInputs type="date" value={payload && reverseDateHelp(payload?.resumptionDate)} onChange={handleChange} name="resumptionDate" label="Resumption Date" />
            {/* <TagInputs value={payload && payload?.stateOfOrigin} onChange={handleChange} name="workGrade" label="Last Login" /> */}
            {
                editable &&
                <div className="flex m-t-20">
                    <button onClick={previous} className="btn  w-100" >Previous</button>
                    <button onClick={next} className="btn w-100 m-l-10" >Submit</button>
                </div>
            }
        </div>
    )
}
export default OtherInfoForm;