import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";
import TagInputs from "../../layouts/TagInputs";
import { get, post } from "../../../utility/fetch";
import notification from "../../../utility/notification";

import JobInfoForm from "./forms/JobInfoForm";
import PersonalInfoForm from "./forms/PersonalInfoForm";
import OtherInfoForm from "./forms/OtherInfoForm";
import AssignPrivileges from "./forms/AssignPrivileges";
import UserTable from "../../tables/UserTable";

let gender = [
  { value: "choose", name: "Choose Gender" },
  { value: "Male", name: "Male" },
  { value: "Female", name: "Female" },

]
let maritalStatus = [
  { value: "choose", name: "Choose Marital Status" },
  { value: "Single", name: "Single" },
  { value: "Married", name: "Married" },
]

function UserRecords({ setSelectedTab, data }) {
  const [payload, setPayload] = useState({});
  const [localTab, setLocalTab] = useState("table");
  const [tableData, setTableData] = useState([]);
  const [totalPaginationCount, setTotalPaginationCount] = useState(1);
  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    data && setPayload(data);
    getUsers()
  }, [data])


  const submitPayload = async () => {
    if (sessionStorage?.getItem("status") === "edit") {
      setSelectedTab("contactDetails");
    }
    else {
      try {
        let res = await post("/patients/AddPatient", { ...payload, clinicId: Number(sessionStorage.getItem("clinicId")) })
        if (res.patientId) {
          notification({ message: res?.messages, type: "success" })
          setSelectedTab("contactDetails")
          sessionStorage.setItem("patientId", res?.patientId)
        }
      } catch (error) {
        notification({ message: error?.detail, type: "error" })
      }
    }
  }

  const getUsers = async () => {
    let users = await get("/user/list/1/10")
    console.log(users)
    setTableData(users?.resultList);
  }
  

  return (
    <div className="w-100 ">
      {localTab === "table" ?
        <div>
              <div payload={payload} handleChange={handleChange}>
                <UserTable data={tableData} />
              </div> 
        </div>
        :
        <div className="w-100 flex">
          <div className="flex-15 m-r-20">
            <div onClick={() => setLocalTab("job")} className={`rounded-button ${localTab === "job" && "bg-green"}`}>Job Information</div>
            <div onClick={() => setLocalTab("personal")} className={`rounded-button ${localTab === "personal" && "bg-green"}`}>Personal Information</div>
            <div onClick={() => setLocalTab("other")} className={`rounded-button ${localTab === "other" && "bg-green"}`}>Other Information</div>
            <div onClick={() => setLocalTab("priviledge")} className={`rounded-button ${localTab === "priviledge" && "bg-green"}`}>Assign Priviledge</div>
          </div>
          <div className="flex-40 m-r-20">
            <div className="">
              {localTab === "job" ?
                <JobInfoForm payload={payload} handleChange={handleChange} /> :
                (localTab === "personal") ?
                  <PersonalInfoForm payload={payload} handleChange={handleChange} /> :
                  (localTab === "other") ?
                    <OtherInfoForm /> :
                    <AssignPrivileges />
              }

              <button onClick={submitPayload} className="btn  m-t-20 w-100" >Continue</button>
            </div>
          </div>
          <div className="flex-30">
            <div className="flex">
              <div className="picture-box m-r-20">
                YO
              </div>
              <div className="picture-box">
                YO
              </div>

            </div>
          </div>
        </div>
      }

    </div>
  );
}

export default UserRecords;
