import { useEffect, useState } from "react";
import TagInputsCheck from "./TagInputCheck";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'

const RoleAssignmentModal = ({ closeModal, payload, setPayload, submit, role }) => {
    
    const handleChange = (event) => {
        console.log(event.target.checked)
        setPayload({...payload, [event.target.name]: event.target.checked });
    }

    console.log(payload)

    const checked = () => {
        setPayload({})
        console.log(role?.rolePrivilegeAccesses)
        let payLoad = {};
        for(let i=0; i<role.rolePrivilegeAccesses.length; i++){
            console.log(role.rolePrivilegeAccesses[i])
            payLoad = {...payLoad, [role.rolePrivilegeAccesses[i].name]:true}
        }
        setPayload(payLoad);    
        console.log(payLoad)    
    }

    useEffect(()=>{
        checked();
    }, [])
    
    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Role Assignment</h3>
                        <TagInputs value={role?.name} name="role" label="Role" />

                        <h3 className="m-t-20 m-b-10">Select Privilege/Rights</h3>
                        <div className="flex space-between w-100 ">
                            <div className="w-80 ">
                                <TagInputsCheck checked={payload["Dashboard"]} className=" w-100" onChange={handleChange} name="Dashboard" label="Dashboard" />
                                <TagInputsCheck checked={payload["User Management"]} className=" w-100" onChange={handleChange} name="User Management" label="User Management" />
                                <TagInputsCheck checked={payload["Facility Management"]} className=" w-100" onChange={handleChange} name="Facility Management" label="Facility Management" />
                            </div>
                            <div className="w-80">
                                <TagInputsCheck checked={payload["Customer Engagement"]} className=" w-100" onChange={handleChange} name="Customer Engagement" label="Customer Engagement" />
                                <TagInputsCheck checked={payload["Patient Management"]} className=" w-100" onChange={handleChange} name="Patient Management" label="Patient Management" />
                                <TagInputsCheck checked={payload["Settings"]} className=" w-100" onChange={handleChange} name="Settings" label="Settings" />
                            </div>
                            <div className="w-80">
                                <TagInputsCheck checked={payload["Referrals"]} className=" w-100" onChange={handleChange} name="Referrals" label="Referrals" />
                                <TagInputsCheck checked={payload["Finance"]} className=" w-100" onChange={handleChange} name="Finance" label="Finance" />
                                <TagInputsCheck checked={payload["Insurance"]} className=" w-100" onChange={handleChange} name="Insurance" label="Insurance" />
                            </div>

                        </div>

                        <button onClick={()=>submit(payload, role?.id)} className="btn w-100 m-t-10">Submit</button>

                    </div>
                </div>
            </div>

        </div >
    )
}

export default RoleAssignmentModal;