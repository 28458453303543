import { useEffect, useState } from "react";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import { get as getPatient } from "../../utility/fetchNurse";
import Notify from "../../utility/notify";

const EquipmentModal = ({ closeModal, setRoomData, recipient, roomId, data, getRooms }) => {
    const [payload, setPayload] = useState({
        equipmentId: 0,
        roomId: roomId,
        location: "",
        patientId: 0,
        assignNote: "",
        quantity: 0,
    });

    const [userNames, setUserNames] = useState([]);
    const [equipment, setEquipment] = useState([]);


    useEffect(() => {
        getUsers();
        getEquipment();
        if (data) {
            setPayload({
                equipmentId: data.equipmentId,
                roomId: data.roomId,
                location: data.location,
                patientId: data.patientId,
                assignNote: data.assignNote,

            })
        }

        setPayload((prev) => (
            {
                ...payload,
                roomId: roomId
            }
        )

        )

    }, [])


    const getUsers = async () => {
        let res = await getPatient("/patients/AllPatient/2?pageIndex=1&pageSize=10000");
        console.log(res)
        let temp = res?.data?.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            value: user.patientId,
        }))

        temp.unshift({ name: "Select Patient", value: 0 })
        setUserNames(temp);
    }

    const getEquipment = async () => {
        let res = await get(`/equipment/list/${1}/${10000}`);
        console.log(res)
        let temp = res?.resultList?.map((data) => ({
            name: data.name,
            value: data.id,
        }))
        temp.unshift({ name: "Select Equipment", value: 0 })
        setEquipment(temp);
    }

    const handleChange = (event) => {
        if (event.target.name === "equipmentId") {
            setPayload({
                ...payload,
                equipmentId: Number(event.target.value),
            })
        }
        else if (event.target.name === "patientId") {
            setPayload({
                ...payload,
                patientId: Number(event.target.value),
            })
        }
        else if (event.target.name === "quantity") {
            setPayload({
                ...payload,
                quantity: Number(event.target.value),
            })
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }

    const assignEquipment = async () => {

        console.log(payload);
        const {
            equipmentId,
            roomId,
            location,
            patientId,
            assignNote,
            quantity
        } = payload;

        if (recipient === "patient") {
            if (
                equipmentId === 0 ||
                roomId === 0 ||
                patientId === 0 ||
                assignNote === "" ||
                !location
            ) {
                Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
                return;
            }
        }
        else {
            if (
                equipmentId === 0 ||
                roomId === 0 ||
                quantity === 0 ||
                assignNote === "" ||
                !location
            ) {
                Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
                return;
            }
        }


        let postPayload;

        if(recipient === "room"){
           postPayload = {
            equipmentId: equipmentId,
            roomId: roomId,
            location: location,
            assignNote: assignNote,
            quantity: quantity,          
           }
        }
        else{
            postPayload = {
                equipmentId: equipmentId,
                roomId: roomId,
                location: location,
                patientId: patientId,
                assignNote: assignNote
            }
        }

       

        let res;
        
        if(recipient === "room"){
            await post(`/assignequipmentroom`, postPayload);
        }
        else{
            await post(`/assignequipmentpatient`, postPayload);
        }
        
        



        console.log(res);
        if (res?.statusCode === 409) {
            Notify({ title: "Error", message: "Equipment Already Assigned", type: "danger" });
            return;
        }
        else if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            Notify({ title: "Success", message: "Equipment Assigned Successfully", type: "success" });
            setPayload({
                equipmentId: 0,
                roomId: roomId,
                location: "",
                patientId: 0,
                assignNote: "",
            });
            closeModal();

        }
    }


    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    {recipient === "patient" && <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Assign an equipment to Patient</h3>
                        <TagInputs type="select" value={data && payload?.patientId} options={userNames} name="patientId" onChange={handleChange} label="Select Patient" />
                        <TagInputs value={data && payload?.location} name="location" onChange={handleChange} label="Location" />
                        <TagInputs type="select" value={data && payload?.equipmentId} options={equipment} name="equipmentId" onChange={handleChange} label="Select Equipment" />
                        {/* <TagInputs variation="number" value={data && payload?.quantity} name="quantity" onChange={handleChange} label="Quantity" /> */}
                        <TagInputs type="textArea" value={data && payload?.assignNote} name="assignNote" onChange={handleChange} label="Notes" />

                        <button onClick={assignEquipment} className="btn w-100 m-t-10">Submit</button>


                    </div>}
                    {recipient === "room" && <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Assign an equipment to Room</h3>
                        {/* <TagInputs type="select" value={data && payload?.patientId} options={userNames} name="patientId" onChange={handleChange} label="Select Patient" /> */}
                        <TagInputs type="select" value={data && payload?.equipmentId} options={equipment} name="equipmentId" onChange={handleChange} label="Select Equipment" />
                        <TagInputs value={data && payload?.location} name="location" onChange={handleChange} label="Location" />
                        <TagInputs variation="number" value={data && payload?.quantity} name="quantity" onChange={handleChange} label="Quantity" />
                        <TagInputs type="textArea" value={data && payload?.assignNote} name="assignNote" onChange={handleChange} label="Notes" />

                        <button onClick={assignEquipment} className="btn w-100 m-t-10">Submit</button>


                    </div>}
                </div>
            </div>

        </div >
    )
}

export default EquipmentModal;