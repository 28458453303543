import { useEffect, useState } from "react";
import HeaderSearch from "../../../Input/HeaderSearch";
import { get } from "../../../utility/fetch";
import EquipmentTable from "../../tables/EquipmentTable";
import ManageEquipmentTable from "../../tables/ManageEquipmentTable";
import Notify from "../../../utility/notify";

const ManageEquipment = () => {
    const [equipmentData, setEquipmentData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [totalPaginationCount, setTotalPaginationCount] = useState(1);
    const [vendorData, setVendorData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState(null);

    const getEquipment = async (pageNumber) => {
        let res = await get(`/equipment/list/${pageNumber}/10`);
        if (res) {
            console.log(res);
            setEquipmentData(res?.resultList);
            setTotalPaginationCount(res?.totalPages*10);
        }
    }

    const handleSearch = async (e) => {
        let query = e.target.value;
        setQuery(e.target.value);
            let res = await get(`/equipment/filter-list/${"Name"}/${query}/${pageNumber}/10`);
            console.log(res);
            if (res?.statusCode === 404) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 500) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 409) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else if (res?.statusCode === 401) {
                Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
                return;
            }
            else {
                setEquipmentData(res?.resultList);
                setTotalPaginationCount(res?.totalPages * 10)
            }
    }


    const handleShowModal = () =>{
        setVendorData(null);
        setShowModal(true);
    }

    useEffect(() => {
        getEquipment();
    }, [])

    return (
        <div>
            <div className="flex space-between">
                <div>
                    <h3>{totalPaginationCount} Profiled Equipments</h3>
                </div>

                <div className="flex">
                    <div className="m-r-10"><HeaderSearch onChange = {handleSearch}/></div>
                    <div><button onClick={handleShowModal} className="btn w-100 round-5">+ Add Equipment</button></div>
                    <div></div>
                </div>

            </div>
          <ManageEquipmentTable data={equipmentData} vendorData={vendorData} setVendorData = {setVendorData} getEquipment={getEquipment} showModal={showModal} totalPaginationCount={totalPaginationCount} setShowModal = {setShowModal} />
        </div>
    )
}

export default ManageEquipment;