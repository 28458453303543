import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";
import TagInputs from "../../layouts/TagInputs";
import { del, get, post } from "../../../utility/fetch";
import notification from "../../../utility/notification";
import RolesTable from "../../tables/RolesTable";
import Notify from "../../../utility/notify";
let gender = [
  { value: "choose", name: "Choose Gender" },
  { value: "Male", name: "Male" },
  { value: "Female", name: "Female" },

]
let maritalStatus = [
  { value: "choose", name: "Choose Marital Status" },
  { value: "Single", name: "Single" },
  { value: "Married", name: "Married" },
]
function CreateRole({ setSelectedTab, data, getRoles, total }) {
  const [payload, setPayload] = useState({});

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value })
  }

  const submitPayload = async () => {
    if(!payload?.name || payload?.name === ""){
      Notify({title:"Error", message: "Please Enter Role Name", type: "danger" })
      return
    }
      try {
        let res = await post("/role", { ...payload, createdBy: Number(sessionStorage.getItem("userId") )})
        if(res.statusCode == 409){
          Notify({ title:"Error", message: "Role Name Already Exists", type: "danger" });
          return;
        }
        else if(res?.statusCode === 400){
          Notify({ title:"Error", message: res?.errorData[0], type: "danger" });
          return;
        }
        else if(res.statusCode === 500){
          Notify({ title:"Error", message: res?.errorData[0], type: "danger" });
          return;
        }
       else{
          Notify({title:"Success", message: "Role Created Successfully", type: "success" });
          // setSelectedTab("contactDetails")
          // sessionStorage.setItem("patientId", res?.patientId)
          getRoles();
          setPayload({
            name:""
          });

        }
      } catch (error) {
        notification({ message: error?.detail, type: "error" })
      }
    
  }

 

  return (
    <div className="w-100">
      {" "}
      <div className="m-t-40"></div>
      <TagInputs value={payload?.name} onChange={handleChange} name="name" label="Name Of Role" />
      <button onClick={submitPayload} className="btn  m-t-20 w-100" >Add Role</button>

      <RolesTable total={total}  data = {data}/>

    </div>
  );
}

export default CreateRole;
