import React, { useEffect, useState } from "react";
import InputField from "../../UI/InputField";
import uploadImage from "../../../assets/images/uploadIcon.png"
import TagInputs from "../../layouts/TagInputs";
import { get, post } from "../../../utility/fetch";
import notification from "../../../utility/notification";
import JobInfoForm from "../Settings/forms/JobInfoForm";
import PersonalInfoForm from "../Settings/forms/PersonalInfoForm";
import OtherInfoForm from "../Settings/forms/OtherInfoForm";
import AssignPrivileges from "../Settings/forms/AssignPrivileges";
import UserTable from "../../tables/UserTable";
import { dateHelp } from "../../../utility/general";
import axios from "axios";
import Notify from "../../../utility/notify";


let gender = [
  { value: "choose", name: "Choose Gender" },
  { value: "Male", name: "Male" },
  { value: "Female", name: "Female" },

]
let maritalStatus = [
  { value: "choose", name: "Choose Marital Status" },
  { value: "Single", name: "Single" },
  { value: "Married", name: "Married" },
]

function Onboard({ setSelectedTab, edit, roleList, setRoleList, sendPayload, setEdit, getProfiles, payload, onboardId, setPayload, data, ...props }) {
  const [localTab, setLocalTab] = useState("job");
  const [tableData, setTableData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = (event) => {
    if (event.target.name === "isSuperAdmin") {

      if (event.target.checked === "false" || event.target.checked === false) {
        setPayload({ ...payload, [event.target.name]: false })
      }
      else if (event.target.checked === "true" || event.target.checked === true) {
        setPayload({ ...payload, [event.target.name]: true })
      }
    }
    if (event.target.name === "roleIds") {
      console.log(payload?.roleIds)

      let exist = payload?.roleIds?.find((item)=> item.roleId === Number(event.target.value));

      


      if (isNaN(Number(event.target.value)) || exist){
        return
      }
      else {
        let ids = payload?.roleIds
        ids = [...ids, {roleId: Number(event.target.value)}];
        setPayload({ ...payload, [event.target.name]: ids })
      }


    }
    else if (event.target.name === "nationalityId" || event.target.name === "religionId" || event.target.name === "stateId") {


      if (event.target.value === "0" || event.target.value === "Select Role") {
        setPayload({ ...payload, [event.target.name]: 0 })
      }
      else {
        setPayload({ ...payload, [event.target.name]: Number(event.target.value) })
      }
    }
    else if (event.target.name === "dateOfBirth" || event.target.name === "weddingAnniversary" || event.target.name === "resumptionDate") {
      setPayload({ ...payload, [event.target.name]: dateHelp(event.target.value) })
    }

    else {
      setPayload({ ...payload, [event.target.name]: event.target.value })
    }
  }

  const FileUpload = async (data, callbackfunction, type) => {
    let fileProgress;
    let Access_token = sessionStorage.getItem('token');
    const response = axios.post(
      `${process.env.REACT_APP_BASE_URL}/services/api/documents/clinic-admin`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Access_token}`,
          "content-type": "multipart/form-data",
          'content-type': undefined,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      },
    );
    await response
      .then((response) => {
        let result = response.data;
        callbackfunction(result);
        setUploadProgress(0);
        console.log(response);
        if (type === "signature") {
          setPayload({ ...payload, signature: response?.data?.doclink })
        }
        else {
          setPayload({ ...payload, profilePicture: response?.data?.doclink })
        }
      })
      .catch((error) => {
        callbackfunction(error);

      });
  };

  const handleImage = (e, f) => {
    const data = new FormData();
    const supportedTypes = [
      'jpeg',
      'png',
      'gif',
      'pdf',
      "msword",
      "ppt", "pptx", "vnd.ms-excel", "xls", "xlsx",
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const { type, size } = e.target.files[0] ?? '';

    let doc = e.target.files[0];

    const fileType = type.slice(type.indexOf('/') + 1);

    let defObj = {
      docTitle: '',
      docPath: ''
    };

    if (!supportedTypes.includes(fileType)) {
      Notify({
        title: "Upload Error",
        message: "Unsopported file type",
        type: "danger"
      })
    }



    if (supportedTypes.includes(fileType) && size / 1024 < 500000) {
      data.append('files', doc);
      FileUpload(data, (response) => {
        if (response.code === 1) {
          defObj.docTitle = doc.name;
          defObj.docPath = response.doclink;
          props.sendImagg && props.sendImagg(defObj);
        } else if (response.code === 0) {
          Notify({
            title: "Upload Error",
            message: response?.message,
            type: "danger"
          })

        } else {
          Notify({
            title: "Upload Error",
            message: response?.message,
            type: "danger"
          })

        }
      }, f);
    }
  };



  useEffect(() => {
    data && setPayload(data);
    getUsers()
  }, [data])


  const getUsers = async () => {
    let users = await get("/user/list/1/10")
    console.log(users)
    setTableData(users?.resultList);
  }

  return (
    <div className="w-100 ">
      <div className="w-100 flex">
        <div className="flex-15 m-r-20">
          <div
            // onClick={() => setLocalTab("job")}
            className={`rounded-button ${localTab === "job" && "bg-green"}`}
          >
            Job Information
          </div>

          <div
            // onClick={() => setLocalTab("personal")}
            className={`rounded-button ${localTab === "personal" && "bg-green"}`}
          >
            Personal Information
          </div>

          <div
            // onClick={() => setLocalTab("other")}
            className={`rounded-button ${localTab === "other" && "bg-green"}`}
          >
            Other Information
          </div>

        </div>
        <div className="flex-40 m-r-20">
          <div className="">
            {localTab === "job" ?
              <JobInfoForm edit={edit} setEdit={setEdit} roleList={roleList} editable={true} getProfiles={getProfiles} setPayload={setPayload} payload={payload} handleChange={handleChange} setLocalTab={setLocalTab} onboardId={onboardId} /> :
              (localTab === "personal") ?
                <PersonalInfoForm edit={edit} setEdit={setEdit} editable={true} payload={payload} handleChange={handleChange} setLocalTab={setLocalTab} /> :
                <OtherInfoForm sendPayload={sendPayload} edit={edit} setEdit={setEdit} getProfiles={getProfiles} setSelectedTab={setSelectedTab} setPayload={setPayload} onboardId={onboardId} editable={true} payload={payload} handleChange={handleChange} setLocalTab={setLocalTab} />
            }

          </div>
        </div>
        <div className="flex-30">
          <div className="flex w-100">
            <label className="w-100 m-r-20 ">
              <input onChange={(e) => handleImage(e, "profilePicture")} type="file" hidden className="w-100" />
              <img src={payload?.profilePicture || uploadImage} className={`picture-box ${!payload?.profilePicture && "p-20 m-r-20 w-100 h-100"} pointer`} />
              {!payload?.profilePicture && <p className="center-text">Profile Picture</p>}
            </label>
            <label className="w-100 ">
              <input onChange={(e) => handleImage(e, "signature")} type="file" hidden className="w-100" />
              <img src={payload?.signature || uploadImage} className={`picture-box ${!payload?.signature && "p-20 w-100"} pointer`} />
              {!payload?.signature && <p className="center-text">Signature</p>}

            </label>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Onboard;
