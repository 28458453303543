import { useEffect, useState } from "react";
import TagInputs from "../../../layouts/TagInputs";
import { get } from "../../../../utility/fetch";
import { reverseDateHelp } from "../../../../utility/general";
import Notify from "../../../../utility/notify";

var today = new Date().toISOString().split('T')[0];

var limitDate = new Date();
limitDate?.setFullYear((limitDate.getFullYear() - 18 ));
var limit = limitDate?.toISOString()?.split('T')[0];

let gender = [
    { value: "", name: "Select Gender" },
    { value: "Male", name: "Male" },
    { value: "Female", name: "Female" },
  
  ];

  let maritalStatus = [
    { value: "", name: "Select Marital Status" },
    { value: "Single", name: "Single" },
    { value: "Married", name: "Married" },
  ];

  let titleOptions = [
    { value: "choose", name: "Select Title" },
    { value: "Miss", name: "Miss" },
    { value: "Mr", name: "Mr" },
    { value: "Mrs", name: "Mrs" },
  ]

const PersonalInfoForm = ({editable, setLocalTab, payload, handleChange }) => {
  const [religions, setReligions] = useState();
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();

  // const getReligions = async () => {
  //   const res = await get("/profile/religion/list/1/100");

  //   let religionList = res?.resultList;

  //   religionList.unshift({name:"Select Religion", id:""});

  //   setReligions(res.resultList?.map(religion => ({name: religion.name, value: religion.id})));
  //   console.log(res)
  // };

  const getCountries = async () => {
    const res = await get("/profile/nationality/list/1/100");
    setCountries(res.resultList?.map(country => ({name: country.name, value: country.id})));
    console.log(res)
  };

  const getStates = async () => {
    let res = await get("/profile/state/list/1/100");
    let statesList = res?.resultList;

    statesList.unshift({name:"Select State", id:""});
    setStates(statesList?.map(state => ({name: state.name, value: state.id})));
    console.log(res)
  };


  const next = () =>{
    let { dateOfBirth, stateId, firstName, middleName, lastName, phoneNumber, gender, religionId, placeOfBirth, nationalityId, motherMaidenName, maritalStatus, weddingAnniversary, title} = payload;
        if (!stateId) {
            Notify({ title: "Error", message: "Please select a state", type: "danger" });
            return;
        }
        if (!dateOfBirth) {
            Notify({ title: "Error", message: "Please select a date of birth", type: "danger" });
            return;
        }
        if(title === "choose"){
          Notify({ title: "Error", message: "Please select a title", type: "danger" });
          return;
        }
        if(!firstName){
            Notify({ title: "Error", message: "Please enter a first name", type: "danger" });
            return;
        }
        // if(!middleName){
        //     Notify({ title: "Error", message: "Please enter a middle name", type: "danger" });
        //     return;
        // }
        if(!lastName){
            Notify({ title: "Error", message: "Please enter a last name", type: "danger" });
            return;
        }
        if(!gender){
          Notify({ title: "Error", message: "Please select a gender", type: "danger" });
          return;
        }
        if(!maritalStatus){
          Notify({ title: "Error", message: "Please select a marital status", type: "danger" });
          return;
        }
        if(!nationalityId){
          Notify({ title: "Error", message: "Please select a nationality", type: "danger" });
          return;
        }
        // if(!religionId){
        //   Notify({ title: "Error", message: "Please select a religion", type: "danger" });
        //   return;
        // }
        if(!phoneNumber || !phoneNumber.startsWith("+") || phoneNumber.length !== 14){
            Notify({ title: "Error", message: "Please enter a phone number in the format +2341112222345", type: "danger" });
            return;
        }
        if(!placeOfBirth){
            Notify({ title: "Error", message: "Please enter a place of birth", type: "danger" });
            return;
        }
        // if(!weddingAnniversary){
        //     Notify({ title: "Error", message: "Please select a wedding anniversary", type: "danger" });
        //     return;
        // }
        if(!motherMaidenName){
            Notify({ title: "Error", message: "Please enter a mother's maiden name", type: "danger" });
            return;
        }

         
    setLocalTab("other")
  }

  const previous = () => {
    setLocalTab("job")
}

useEffect(() =>{
  // getReligions()
  getCountries()
  getStates()
}, [])

    return (
        <div className="w-100">
            <TagInputs type="password" value={payload && payload?.password} onChange={handleChange} name="password"  label="Password" />
            <TagInputs value={payload && payload?.title} type="select" options={titleOptions} onChange={handleChange} name="title" label="Title" />
            <TagInputs value={payload && payload?.firstName} onChange={handleChange} name="firstName" label="First Name" />
            <TagInputs value={payload && payload?.middleName} onChange={handleChange} name="middleName" label="Middle Name" />
            <TagInputs value={payload && payload?.lastName} onChange={handleChange} name="lastName" label="Last Name" />
            <TagInputs type="number" value={payload && payload?.phoneNumber} onChange={handleChange} name="phoneNumber" label="Phone Number" />
            <TagInputs value={payload && payload?.gender} onChange={handleChange} name="gender" type="select" label="Gender" options={gender} />
            {/* <TagInputs value={payload && payload?.religionId} onChange={handleChange} name="religionId" type="select" label="Religion" options={religions} /> */}
            <TagInputs minDate={limit} value={payload && reverseDateHelp(payload?.dateOfBirth)} onChange={handleChange} name="dateOfBirth" type="date" label="Date Of Birth" />
            <TagInputs  value={payload && payload?.placeOfBirth} onChange={handleChange} name="placeOfBirth"  label="Place Of Birth" />
            <TagInputs value={payload && payload?.maritalStatus} onChange={handleChange} name="maritalStatus" type="select" label="Marital Status" options={maritalStatus} />
            <TagInputs value={payload && payload?.motherMaidenName} onChange={handleChange} name="motherMaidenName" label="Mother's Maiden Name" />
           {payload?.maritalStatus === "Married" && <TagInputs minDate={today} value={payload && reverseDateHelp(payload?.weddingAnniversary)} type="date" onChange={handleChange} name="weddingAnniversary" label="Wedding Anniversary" />}
            <TagInputs disabled={true} value={19} options={countries} type="select" onChange={handleChange} name="nationalityId" label="Nationality" />
           { <TagInputs value={payload && payload?.stateId} options={states} type="select" onChange={handleChange} name="stateId" label="State Of Origin" />}
            {
                editable &&
                <div className="flex m-t-20">
                    <button onClick={previous} className="btn m-r-10 w-100" >Previous</button>
                    <button onClick={next} className="btn  w-100" >Next</button>
                </div>
            }
        </div>
    )
}
export default PersonalInfoForm;