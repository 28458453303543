import { useEffect, useState } from "react";
import TagInputsCheck from "./TagInputCheck";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import { get as getPatient } from "../../utility/fetchNurse";
import notification from "../../utility/notification";
import Notify from "../../utility/notify";
import axios from "axios";

const assignOptions = [
    {
        name: "Select Action",
        value: ""
    },
    {
        name: "Assign",
        value: "Occupied"
    },
    {
        name: "Unassign",
        value: "Vacant"
    },
    {
        name: "Unavailable",
        value: "Unavailable"
    },
]

const BedAssignModal = ({ closeModal, getVendor, getAssignBeds, getBeds, data }) => {
    const [payload, setPayload] = useState({
        patientAssignedName: "",
        bedId: data.id,
        patientAssignedId: 0,
        assignNote: "",
        assignerUserId: Number(sessionStorage?.getItem("userId"))
    });
    const [selectedStaff, setSelectedStaff] = useState();
    const [status, setStatus] = useState("");
    const [assignedBeds, setAssignedBeds] = useState([]);

    useEffect(() => {
        // getUsers();
        getAssignedBeds();
    }, []);

    const getAssignedBeds = async (pageNumber = 1) => {
        let res = await get(`/bed/assign-bed/list/${pageNumber}/1000`);
        if (res?.statusCode === 400) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
        }
        else if (res?.statusCode === 409) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
        }
        else if (res?.statusCode === 401) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
        }
        else if (res?.statusCode === 404) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
        }
        else if (res?.statusCode === 500) {
          Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
          return;
        }
        else {
          setAssignedBeds(res.resultList);
        }
      }

    const getUsers = async () => {
        let res = await getPatient(`/patients/AllPatient/${sessionStorage.getItem("clinicId")}?pageIndex=1&pageSize=10000`);
        console.log(res)
        let patientList = res?.data.map((user) => ({
            name: `${user?.firstName} ${user?.lastName}`,
            value: user?.patientId,
        }));

        patientList.unshift({ name: "Select Patient", value: "" })

        setUserNames(patientList);
    }

    const [staffIdInput, setStaffIdInput] = useState("");

    const [userNames, setUserNames] = useState([]);

    const handleChange = (event) => {
        if (event.target.name === "patientAssignedId") {
            setStaffIdInput(event.target.value);
            setPayload({
                ...payload,
                patientAssignedName: userNames.find((item) => item?.value === Number(event.target.value)).name,
                patientAssignedId: Number(event.target.value),
            })
            setSelectedStaff(userNames.find((item) => item?.value === Number(event.target.value)).name);
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }



    const assignBed = async () => {

        const {
            bedId,
            patientAssignedId,
            assignNote } = payload;

    
        if(!status){
            Notify({ title: "Error", message: "Please select action", type: "danger" });
            return;
        }


            
        if (data?.status === "Occupied" && status === "Unavailable") {

            Notify({ title: "Error", message: "Sorry, You can't make unavailable an occupied bed.", type: "danger" });
            return;
        }

        if (data?.status === "Vacant" && status === "Vacant") {

            Notify({ title: "Error", message: "You can't unassign a vacant bed", type: "warning" });
            return;
        }

        if (data?.status === "Unavailable" && status === "Occupied") {

            Notify({ title: "Error", message: "Sorry, You can't assign a patient to an unavailable bed..", type: "danger" });
            return;
        }

        if (!bedId) {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }

console.log(payload)

        if ((status === "Occupied") && (!patientAssignedId || !assignNote)) {
            Notify({ title: "Error", message: "Please fill all the fields", type: "danger" });
            return;
        }

        let res;
        let unassign;

        if (status === "Occupied") {
            res = await post("/bed/assign-bed", { ...payload })
        }
        else if (status === "Vacant") {

            let assId = assignedBeds.find((item) => item.bed.id === bedId)?.id;
            console.log(assId);

            if(assId){
                unassign = await put(`/bed/unassign-bed/${assId}`)

            }
           
            

            if (unassign?.statusCode === 400) {
                Notify({ title: "Error", message: unassign?.errorData[0], type: "danger" });
                return;
            }
            else if (unassign?.statusCode === 409) {
                Notify({ title: "Error", message: unassign?.errorData[0], type: "danger" });
                return;
            }
            else if (unassign?.statusCode === 404) {
                Notify({ title: "Success", message: unassign?.errorData[0], type: "success" });
                return;
            }
            else {
                notification({ message: "Bed Unassigned Successfully", type: "success" });
                getAssignBeds();
                getBeds(1)
                res = await put(`/bed/change-bed-status/${bedId}`, { status })
                closeModal();
            }


        }
        else {
            res = await put(`/bed/change-bed-status/${bedId}`, { status })
        }


        if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 409) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Success", message: res?.errorData[0], type: "success" });
            return;
        }
        else {
            notification({ message: "Bed Status Changed Successfully", type: "success" });
            getAssignBeds();
            getBeds(1)
            closeModal();

        }
    }

    useEffect(() => {
        getUsers();
        if (staffIdInput !== "") {
            getUsers(staffIdInput)
        }

    }, [staffIdInput]);



    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '60%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10">Assign a Patient</h3>
                        <TagInputs type="select" value={status} name="action" options={assignOptions} onChange={(e) => setStatus(e.target.value)} label="Select Action" />

                        {status === "Occupied" && <TagInputs type="select" value={staffIdInput} name="patientAssignedId" options={userNames} onChange={handleChange} label="Select Patient" />}
                        {status === "Occupied" && <TagInputs type="textArea" onChange={handleChange} name="assignNote" label="Additional Notes " />}

                        <button onClick={assignBed} className="btn w-100 m-t-10">Submit</button>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default BedAssignModal;